import * as React from 'react'
import { graphql, Link } from 'gatsby'

import { Box, Container, Grid, Typography } from '@mui/material';
import Breadcrumbs from '../components/Breadcrumbs';
import RichText from '../components/RichText';
import Content from '../components/Content';
import ArticleCard from '../components/cards/ArticleCard';
import DoctorIcon from "../components/icons/DoctorIcon"
import { useTranslation } from '../contexts/TranslationProvider';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import getArticleReadingTime from '../services/articleReadingTime';
import Seo from '../components/Seo';
import buildMetaData from '../services/buildMetaData';
import buildOgpTags from '../services/buildOgpTags';
import { linkResolver } from '../utils/linkResolver';
import addToSchema from '../services/addToSchema';
import getText from '../services/text';



const ArticleTemplate = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const page = data.page || {}
  const recentArticles = data?.recentArticles?.edges.map(({ node }) => node) || {}

  return <>
    <br />
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs breadcrumbs={pageContext.breadcrumbs} />
        </Grid>
      </Grid>
    </Container>
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RichText
            sx={{

            }}
            content={page.data.title.richText}
          />
        </Grid>
        <Grid item xs={12}>
          {page.data.author.document && <Typography
            component={Link}
            to={linkResolver(page.data.author.document)}
            sx={{
              fontSize: '12px',
              paddingBottom: 1,
              display: 'block',
              color: 'inherit'
            }}>
            <DoctorIcon sx={{ fontSize: '12px' }} />&nbsp;
            {page.data.author.document.data.title.text}
          </Typography>}

          <Typography sx={{
            fontSize: '12px',
            paddingBottom: 1
          }}>
            {t('readingTime')} • {getArticleReadingTime(page)} {t('min')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            component={GatsbyImage}
            loading="eager"
            sx={{
              height: 290,
            }}
            alt={page.data.image.alt ?? ''}
            image={getImage(page.data.image)} />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="center">
            {page.data.author.document && <Grid item xs={6} md={3}>
              <Box
                sx={{
                  height: 140,
                  borderRadius: '10px',
                  WebkitMaskImage: '-webkit-radial-gradient(white, black);',
                }}
                component={GatsbyImage}
                image={getImage(page.data.author.document.data.image)}
                alt={page.data.author.document.data.alt || page.data.author.document.data.title.text}
              />
            </Grid>}

            <Grid item xs={6} md={3}>
              <Box
                sx={{
                  height: 140,
                  borderRadius: '10px',
                  WebkitMaskImage: '-webkit-radial-gradient(white, black);',
                  backgroundColor: '#FFF5F5',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 2,
                  textAlign: 'center',
                  fontSize: '24px',
                  color: 'secondary.main',
                  fontWeight: 700,
                  lineHeight: '127%'
                }}
              >
                {page.data.author.document?.data.title.text}
              </Box>
              <br />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{
                backgroundColor: '#F5F5F5',
                borderRadius: '10px',
                WebkitMaskImage: '-webkit-radial-gradient(white, black);',
                padding: 2,
                paddingLeft: {
                  xs: 2,
                  md: 10
                },
                paddingRight: {
                  xs: 2,
                  md: 10
                },
                textAlign: 'center'
              }}>
                {page.data.short_description.text}
              </Box>
            </Grid>
          </Grid>


        </Grid>

      </Grid>

    </Container>

    <Content doc={page} />

    <br />
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            component="h3"
            variant="h3"
            sx={{
              textAlign: 'center'
            }}
          >{t('seeAlso')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {recentArticles.map(article => <Grid item xs={12} sm={4} key={`article-${article.id}`}>
              <ArticleCard article={article} />
            </Grid>)}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </>
}

export const Head = ({ data, pageContext }) => {
  const page = data.page || {}

  const meta = buildMetaData(page)
  const ogp = buildOgpTags(page, pageContext)

  let schema = page.data?.schema_json?.text || ""

  // FAQ
  if (page.data?.faq?.length) {
    schema = addToSchema(schema, {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": page.data.faq.map(({ question: faq }) => (
        {
          "@type": "Question",
          "name": faq.document.data.question.text.replace(/(\r\n|\n|\r)/gm, ""),
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.document.data.answer.text.replace(/(\r\n|\n|\r)/gm, "")
          }
        }
      ))
    })
  }

  // Other
  schema = addToSchema(schema, {
    "@context": "https://schema.org",
    "@type": "Article",
    "Headline": page.data.meta_title,
    "isAccessibleForFree": "True",
    "Id": page.data.url,
    "articleBody": getText(page),
    "timeRequired": `PT${getArticleReadingTime(page)}M`,
    "DateCreated": page.first_publication_date,
    "DatePublished": page.first_publication_date,
    "dateModified": page.last_publication_date,
    "image": {
      "@type": "ImageObject",
      "name": page.data.image.alt,
      "Url": page.data.image.url,
      "height": page.data.image.dimensions.height,
      "width": page.data.image.dimensions.width,
      "representativeOfPage": "True",
    },
    "isPartOf": {
      "@id": page.data.url
    },
    "description": page.data.meta_description,
    "keywords": page.data.meta_keywords,
    "author": {
      "@type": "Person",
      "name": page.data.author.document?.data?.title?.text,
      "url": page.data.author.url
    },
    "Publisher": {
      "@type": "MedicalOrganization",
      "Name": "Dental Hygiene Center",
      "Slogan": "Clinicile de stomatologie Dental Hygiene Center",
      "logo": "https://dentalhygienecenter.ro/static/logo-4524fa3b3dd82493ae12eabe12491ca3.png",
      "url": "https://dentalhygienecenter.ro/"
    },
    "copyrightHolder": {
      "@type": "MedicalOrganization",
      "Name": "Dental Hygiene Center",
      "Slogan": "Clinicile de stomatologie Dental Hygiene Center",
      "logo": "https://dentalhygienecenter.ro/static/logo-4524fa3b3dd82493ae12eabe12491ca3.png",
      "url": "https://dentalhygienecenter.ro/"
    }
  })

  return <Seo schema={schema} ogp={ogp} meta={meta} breadcrumbs={pageContext.breadcrumbs} path={pageContext.url} />
}

export const query = graphql`
  query articlePageQuery(
    $lang: String, 
    $id: String
  ) {
    page: prismicArticle(id: { eq: $id }, lang: { eq: $lang }) {      
      alternate_languages {
        uid
        type
        lang
      }
      lang
      uid
      url
      type
      last_publication_date
      first_publication_date
      data {           
        meta_title
        meta_description
        meta_keywords
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        faq {
          question {
            document {
              ... on PrismicFaq {
                id
                data {
                  answer {
                    text
                  }
                  question {
                    text
                  }
                }
              }
            }
          }
        }
        title {
          richText      
        }
        short_description {
          text
        }        
        author {
          url
          document {
            ... on PrismicTeamMember {
              id
              uid              
              type          
              lang
              data {
                title {
                  text
                }
                image {
                  alt
                    dimensions{
                     height
                     width
                    }
                  url
                  gatsbyImageData(width: 300)
                }
              }
            }
          }
        }
        image {
          alt
          url 
          dimensions {
            height
            width
          }
          gatsbyImageData(layout: FULL_WIDTH)
        }
        body {
          ... on PrismicArticleDataBodySimpleArticleText {
            id
            slice_type
            primary {
              text {
                richText
                text
              }
            }
          }



          
          ... on PrismicArticleDataBodyWrappedImage {
            id
            slice_type
            primary {
              wrapped_image_image_size
              wrapped_image_text_size
              wrapped_image_text_background_full
              image_position
              wrapped_image {
                alt
                gatsbyImageData
              }
              wrapped_text {
                richText
                text
              }
            }
          }






          ... on PrismicArticleDataBodyIntro {
            id
            slice_type
            primary {
              intro_headline {
                richText
              }
              intro_box_1_image {
                alt
                gatsbyImageData(width: 400)
              }
              intro_box_2_image {
                alt
                gatsbyImageData(width: 400)
              }
              intro_description {
                richText
              }
              intro_box_3_text {
                richText
              }
            }
          }





          ... on PrismicArticleDataBodyCarousel {
            id
            slice_type
            items {
              slide_image {
                gatsbyImageData(width: 400)
                alt
              }
            }
            primary {
              content {
                text
                richText
              }
            }
          }





          ... on PrismicArticleDataBodyAd {
            id
            slice_type
            items {
              ad_size
              ad {
                document {
                  ... on PrismicAd {
                    id
                    type
                    uid
                    data {
                      title {
                        text
                      }
                      subtitle
                      url
                      left_upper_text {
                        richText
                      }
                      left_lower_text {
                        richText
                      }
                      image {
                        alt
                        gatsbyImageData(height: 255)
                      }
                    }
                  }


                  ... on PrismicBanner {
                    id
                    type
                    uid
                    data {
                      type
                      title {
                        text
                      }
                      subtitle                      
                      button_text
                      url
                      image {
                        alt
                        gatsbyImageData
                      }                      
                    }
                  }
                }
              }
            }
          }





          
          ... on PrismicArticleDataBodyAboutUs {
            id
            slice_type
            primary {
              about_us_title {
                richText
              }
              about_us_description {
                richText
              }
              about_us_upper_left_text
              about_us_upper_right_text
              about_us_lower_left_text
              about_us_lower_right_text
            }
          }



        }
      }
    }

    recentArticles: allPrismicArticle(      
      limit: 3
      sort: {order: DESC, fields: first_publication_date}
      filter: {
        lang: {eq: $lang}        
        id: { ne: $id }
      }
    ) {
      edges {
        node {
          lang
          uid
          id
          type
          data {
            title {
              text
            }
            short_description {
              text
            }            
            author {
              document {
                ... on PrismicTeamMember {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
            image {
              alt
              gatsbyImageData(width: 400)
            }
            body {
              ... on PrismicArticleDataBodySimpleArticleText {
                id
                slice_type
                primary {
                  text {
                    richText
                    text
                  }
                }
              }
              ... on PrismicArticleDataBodyWrappedImage {
                id
                slice_type
                primary {
                  image_position
                  wrapped_image {
                    alt
                    url
                    gatsbyImageData
                  }
                  wrapped_text {
                    richText
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ArticleTemplate